<template>
  <div>
    <a-card title="出库通知单">
      <a-button slot="extra" type="primary" ghost @click="$router.go(-1)"> <a-icon type="left" />返回 </a-button>

      <a-spin :spinning="loading">
        <a-form-model ref="form" :model="form" :rules="rules" :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }">
          <a-row>
            <a-col :span="24" style="width: 320px">
              <a-form-model-item prop="number" label="出库通知单号">
                <a-input v-model="form.number" allowClear />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" style="width: 320px">
              <a-form-model-item prop="client" label="客户">
                <client-select
                  v-model="form.client"
                  :warehouse="currentWarehouse"
                  @refreshClient="refreshClient"
                  @select="changeClient"
                ></client-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="24" style="width: 320px">
              <a-form-model-item prop="warehouse" label="仓库">
                <a-select
                  v-model="form.warehouse"
                  style="width: 100%"
                  @change="
                    form.area = undefined;
                    form.area_name = undefined;
                    form.client = undefined;
                  "
                >
                  <a-select-option v-for="item in warehouseItems" :key="item.id" :value="item.id">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="24" style="width: 320px">
              <a-form-model-item prop="area" label="库区">
                <AreaSelect v-model="form.area" :warehouse="form.warehouse" :name="form.area_name" />
              </a-form-model-item>
            </a-col>

            <a-col :span="24" style="width: 320px">
              <a-form-model-item prop="order_type" label="订单类型">
                <a-select v-model="form.order_type" style="width: 100%">
                  <a-select-option v-for="item in orderTypeItems" :key="item.id" :value="item.id">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>

            <a-col :span="24" style="width: 320px">
              <a-form-model-item prop="external_number" label="客户单号">
                <a-input v-model="form.external_number" allowClear />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" style="width: 320px">
              <a-form-model-item prop="customer_contact" label="客户联系人">
                <DeliveryClientSearchInput
                  v-model="form.customer_contact"
                  :client="form.client"
                  @select="selectDeliveryClient"
                />
                <!-- <a-input v-model="form.customer_contact" allowClear /> -->
              </a-form-model-item>
            </a-col>
            <a-col :span="24" style="width: 320px">
              <a-form-model-item prop="receiving_address" label="送货地址">
                <a-input v-model="form.receiving_address" allowClear />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" style="width: 320px">
              <a-form-model-item prop="contact_phone" label="联系人电话">
                <a-input v-model="form.contact_phone" allowClear />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" style="width: 320px">
              <a-form-model-item prop="delivery_date" label="预计出库日">
                <a-date-picker v-model="form.delivery_date" valueFormat="YYYY-MM-DD HH:mm:ss" style="width: 100%" />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" style="width: 320px">
              <a-form-model-item prop="arrival_date" label="预计到货日">
                <a-date-picker v-model="form.arrival_date" valueFormat="YYYY-MM-DD HH:mm:ss" style="width: 100%" />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" style="width: 320px">
              <a-form-model-item prop="delivery_method" label="配送方式">
                <a-input v-model="form.delivery_method" disabled allowClear />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" style="width: 320px">
              <a-form-model-item prop="express" label="承运公司">
                <a-select
                  v-model="form.express"
                  style="width: 100%"
                  show-search
                  :filter-option="filterExpressOption"
                  @change="changeExpress"
                >
                  <a-select-option value="市内配送">市内配送</a-select-option>
                  <a-select-option value="顺丰快递">顺丰快递</a-select-option>
                  <a-select-option value="德邦快递">德邦快递</a-select-option>
                  <a-select-option value="中通快递">中通快递</a-select-option>
                  <a-select-option value="京东快递">京东快递</a-select-option>
                  <a-select-option value="京东物流">京东物流</a-select-option>
                  <a-select-option value="德邦物流">德邦物流</a-select-option>
                  <a-select-option value="亚德物流">亚德物流</a-select-option>
                  <a-select-option value="顺丰同城">顺丰同城</a-select-option>
                  <a-select-option value="货拉拉">货拉拉</a-select-option>
                  <a-select-option value="闪送">闪送</a-select-option>
                  <a-select-option value="自提">自提</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="24" style="width: 320px">
              <a-form-model-item prop="logistics_number" label="物流单号">
                <a-input v-model="form.logistics_number" allowClear />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" style="width: 320px">
              <a-form-model-item prop="packaging_requirements" label="包装要求">
                <a-input v-model="form.packaging_requirements" allowClear />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" style="width: 320px">
              <a-form-model-item prop="delivery_time" label="送货时间">
                <a-date-picker v-model="form.delivery_time" valueFormat="YYYY-MM-DD HH:mm:ss" style="width: 100%" />
              </a-form-model-item>
            </a-col>

            <a-col :span="24" style="width: 320px">
              <a-form-model-item prop="remark" label="备注">
                <a-input v-model="form.remark" allowClear />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" style="width: 320px">
              <a-form-model-item prop="delivery_files" label="附件">
                <delivery-file-upload v-model="form.delivery_files" />
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>

        <a-divider></a-divider>

        <div>
          <a-row :gutter="16">
            <a-col :span="24" :md="8" :xl="6" style="width: 256px">
              <a-input v-model="materialNumber" placeholder="请扫描产品编号" @keyup.enter.native="scanMaterial" allowClear />
            </a-col>
            <a-space>
              <a-button type="primary" @click="openInventoryModal">选择产品</a-button>
              <a-button type="default" @click="batchAutoPicking">自动拣配</a-button>
            </a-space>
          </a-row>

          <div style="margin-top: 16px">
            <a-table
              size="small"
              :loading="tableLoading"
              :columns="columns"
              :data-source="goodsData"
              :pagination="false"
              :scroll="{ x: 1520 }"
              :rowSelection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange, getCheckboxProps: getCheckboxProps }"
            >
              <div slot="status" slot-scope="value, item">
                <a-select
                  v-if="!item.isTotal"
                  v-model="item.status"
                  size="small"
                  style="width: 100px"
                  @change="item.batch = undefined"
                >
                  <a-select-option value="qualified">良品</a-select-option>
                  <a-select-option value="unqualified"> 不良品 </a-select-option>
                </a-select>
              </div>
              <div slot="batch" slot-scope="value, item">
                <BatchSearchSelect
                  v-if="!item.isTotal"
                  ref="batchSearch"
                  v-model="item.batch"
                  placeholder="批次"
                  :warehouse="form.warehouse"
                  :material="item.material"
                  :client="form.client"
                  :status="item.status"
                  size="small"
                  :defaultItem="item"
                  @autoBackBatch="autoBackBatch"
                  @stopLoading="stopLoading"
                />
              </div>
              <div slot="total_quantity" slot-scope="value, item">
                <div v-if="item.isTotal">{{ item.total_quantity }}</div>
                <a-input-number v-else v-model="item.total_quantity" size="small"></a-input-number>
              </div>
              <div slot="weight" slot-scope="value, item">
                <span v-if="item.total_quantity && item.material_net_weight">
                  {{ NP.times(item.total_quantity, item.material_net_weight || 0, 0.001) }}
                </span>
              </div>
              <div slot="volume" slot-scope="value, item">
                <span v-if="item.total_quantity && item.material_volume">
                  {{ NP.times(item.total_quantity, item.material_volume || 0) }}
                </span>
              </div>
              <div slot="remark" slot-scope="value, item, index">
                <a-input v-model="item.remark" size="small"></a-input>
              </div>
              <div slot="action" slot-scope="value, item, index">
                <a-button-group v-if="!item.isTotal" size="small">
                  <a-button type="danger" @click="removeMaterial(item, index)">删除</a-button>
                  <a-button type="primary" @click="copyMaterial(item, index)">复制</a-button>
                  <a-button @click="autoPicking(item, index)">自动拣配</a-button>
                </a-button-group>
              </div>
            </a-table>
          </div>
        </div>
      </a-spin>

      <div style="margin-top: 32px">
        <a-popconfirm title="确定创建吗?" @confirm="create">
          <a-button type="primary" :loading="loading">创建</a-button>
        </a-popconfirm>
      </div>
    </a-card>

    <inventory-select-modal
      v-model="materialModalVisible"
      :warehouse="form.warehouse"
      :client="form.client"
      @select="onSelectMaterial"
      @confirm="onConfirm"
    >
    </inventory-select-modal>
  </div>
</template>

<script>
import { getDeliveryNumber } from "@/api/data";
import { batchOption, inventoryOption, orderTypeOption, warehouseOption } from "@/api/option";
import { deliveryDetail, deliveryMaterials, deliveryOrderCreate } from "@/api/stockOut";
import moment from "moment";
import NP from "number-precision";
import { mapState } from "vuex";
import { columns } from "./columns";
import { rules } from "./rules";

export default {
  components: {
    DeliveryClientSearchInput: () => import("@/components/DeliveryClientSearchInput/"),
    ClientSelect: () => import("@/components/ClientSelect/"),
    AreaSelect: () => import("@/components/AreaSelect/"),
    DeliveryFileUpload: () => import("@/components/DeliveryFileUpload/index"),
    InventorySelectModal: () => import("@/components/InventorySelectModal/index"),
    BatchSearchSelect: () => import("@/components/BatchSearchSelect/index"),
  },
  inject: ["reloadPage"],
  computed: {
    ...mapState({
      curWarehouse: (state) => state.user.currentWarehouse,
    }),
  },
  data() {
    return {
      NP,
      rules,
      moment,
      columns,
      loading: false,
      tableLoading: false,
      form: { number: "", client: undefined, customer_contact: "", contact_phone: "", receiving_address: "" },
      deliveryMaterialItems: [],
      orderTypeItems: [],

      clientItems: [],
      warehouseItems: [],
      materialNumber: "",
      materialModalVisible: false,

      selectedRowKeys: [],
    };
  },
  computed: {
    goodsData() {
      // 统计合计
      let totalQuantity = 0;
      for (let item of this.deliveryMaterialItems) {
        totalQuantity = NP.plus(totalQuantity, item.total_quantity);
      }
      return [
        ...this.deliveryMaterialItems,
        {
          id: "-1",
          isTotal: true,
          name: "",
          total_quantity: totalQuantity,
        },
      ];
    },
    currentWarehouse() {
      return this.$store.state.user.currentWarehouse;
    },
  },
  methods: {
    refreshClient() {
      if (!this.$route.query.id) {
        // let { client, ...res } = this.form;
        // let newObject = {};
        // newObject = res;
        // this.form = newObject;
        this.deliveryMaterialItems = [];
      }
    },
    getCheckboxProps(record) {
      return {
        props: {
          disabled: record.isTotal,
        },
      };
    },
    initData() {
      this.resetForm();
      let deliveryOrder = this.$route.query.id;
      if (deliveryOrder) {
        deliveryDetail({ id: deliveryOrder }).then((data) => {
          this.form = data;
          getDeliveryNumber().then((data) => {
            this.form.number = data.number;
          });
        });

        deliveryMaterials({ id: deliveryOrder }).then((data) => {
          this.deliveryMaterialItems = data;
        });
      } else {
        getDeliveryNumber().then((data) => {
          this.form.number = data.number;
        });
      }

      warehouseOption({ page_size: 999999 }).then((data) => {
        this.warehouseItems = data.results;
      });

      orderTypeOption({ page_size: 999999 }).then((data) => {
        this.orderTypeItems = data.results;
      });
    },
    async onConfirm(items) {
      for (const item of items) {
        await this.onSelectMaterial(item);
      }
    },
    changeClient(item) {
      if (item.id) {
        this.form.warehouse = item.warehouse;
        this.form.area = item.reservoir_area;
        this.form.area_name = item.reservoir_area_name;
      }
    },
    selectDeliveryClient(item) {
      this.form.contact_phone = item.contact_phone;
      this.form.receiving_address = item.receiving_address;
    },
    async onSelectMaterial(item) {
      // this.tableLoading = true;
      // let curBatch = await this.autoBackBatch(item.batch_items);
      // this.deliveryMaterialItems.splice(0, 0, {
      //   id: item.material,
      //   material: item.material,
      //   material_name: item.material_name,
      //   material_english_name: item.material_english_name,
      //   material_number: item.material_number,
      //   total_quantity: 1,
      //   unit: item.unit,
      //   material_volume: item.material_volume,
      //   material_weight: item.material_weight,
      //   material_net_weight: item.material_net_weight,
      //   material_spec: item.material_spec,
      //   status: "qualified",
      //   remark: "",
      //   available_quantity: item.available_quantity,
      //   batch: curBatch ? curBatch.id : "",
      //   batch_number: curBatch ? curBatch.number : "",
      // });

      let index = this.deliveryMaterialItems.findIndex((_item) => _item.material == item.id);
      if (index !== -1) this.$message.warn(`产品[${item.material_number}] 已重复添加`);

      const data = await batchOption({
        page: 1,
        page_size: 999999,
        has_stock: true,
        warehouse: this.form.warehouse,
        client: this.form.client,
        material: item.material,
      });
      let batchItem = data.results.length > 0 ? data.results[0] : {};

      this.deliveryMaterialItems.push({
        id: item.material,
        material: item.material,
        material_name: item.material_name,
        material_number: item.material_number,
        total_quantity: 1,
        unit: item.unit,
        status: "qualified",
        remark: "",
        available_quantity: item.available_quantity,
        batch: batchItem.id,
        batch_number: batchItem.number,
        material_volume: item.material_volume,
        material_weight: item.material_weight,
        material_spec: item.material_spec,
        material_english_name: item.material_english_name,
      });
      // batchOption({
      //   page: 1,
      //   page_size: 999999,
      //   has_stock: true,
      //   warehouse: this.form.warehouse,
      //   client: this.form.client,
      //   material: item.material,
      // }).then((data) => {
      //   let batchItem = data.results.length > 0 ? data.results[0] : {};

      //   this.deliveryMaterialItems.push({
      //     id: item.material,
      //     material: item.material,
      //     material_name: item.material_name,
      //     material_number: item.material_number,
      //     total_quantity: 1,
      //     unit: item.unit,
      //     status: "qualified",
      //     remark: "",
      //     available_quantity: item.available_quantity,
      //     batch: batchItem.id,
      //     batch_number: batchItem.number,
      //     material_volume: item.material_volume,
      //     material_weight: item.material_weight,
      //     material_spec: item.material_spec,
      //     material_english_name: item.material_english_name,
      //   });
      // });
    },
    autoBackBatch(batches) {
      return new Promise((resolve, reject) => {
        let hasQuantityArr = batches.filter((item) => item.qualified_quantity > 0 && item.remain_quantity > 0);
        if (hasQuantityArr.length) {
          let afterSort = this.DOAsort(batches, "id", "Asc");
          resolve(afterSort[0]);
        } else {
          resolve(false);
        }
      });
    },
    DOAsort(itemList, sortProperty, sortWay) {
      var objectList = new Array();
      function recombineObj(item) {
        var _this = this;
        for (var key of Object.keys(item)) {
          _this[key] = item[key];
        }
      }
      itemList.map(function (item, index) {
        objectList.push(new recombineObj(item));
      });
      switch (sortWay) {
        case "Des":
          objectList.sort(function (a, b) {
            return b[sortProperty] - a[sortProperty];
          });
          break;
        case "Asc":
          objectList.sort(function (a, b) {
            return a[sortProperty] - b[sortProperty];
          });
          break;
        default:
          return objectList;
      }
      return objectList;
    },
    create() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.deliveryMaterialItems.length == 0) {
            this.$message.warn("未添加产品");
            return;
          }

          for (let item of this.deliveryMaterialItems) {
            if (!item.batch) {
              this.$message.warn("未选择批次");
              return;
            }
          }

          let formData = { ...this.form, delivery_material_items: this.deliveryMaterialItems };
          this.loading = true;
          deliveryOrderCreate(formData)
            .then((data) => {
              this.$message.success("创建成功");
              this.$router.go(-1);
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    resetForm() {
      this.form = {
        number: "",
        warehouse: this.curWarehouse,
        customer_contact: "",
        contact_phone: "",
        receiving_address: "",
      };
      this.deliveryMaterialItems = [];
    },
    scanMaterial() {
      if (!this.materialNumber) {
        this.$message.warning("请扫描产品编号！");
        return false;
      }
      if (!this.form.warehouse) {
        this.$message.warn("请选择仓库");
        return;
      }
      if (!this.form.client) {
        this.$message.warn("请选择客户");
        return;
      }

      this.tableLoading = true;
      inventoryOption({ search: this.materialNumber, warehouse: this.form.warehouse, client: this.form.client })
        .then((data) => {
          if (!data.results.length) {
            this.$message.warning("没有找到相关产品，请确认扫描的产品编号！");
            return false;
          }
          let item = data.results[0];
          this.onSelectMaterial(item);
          // const index = this.receiptMaterialItems.findIndex((_item) => _item.material == item.material);
          // if (index !== -1) this.$message.warn(`产品[${item.number}] 已重复添加`);

          // this.deliveryMaterialItems.splice(0, 0, {
          //   id: item.material,
          //   material: item.material,
          //   material_name: item.material_name,
          //   material_english_name: item.material_english_name,
          //   material_number: item.material_number,
          //   total_quantity: 1,
          //   unit: item.unit,
          //   material_volume: item.material_volume,
          //   material_weight: item.material_weight,
          //   material_net_weight: item.material_net_weight,
          //   material_spec: item.material_spec,
          //   status: "qualified",
          //   remark: "",
          //   available_quantity: item.available_quantity,
          // });
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    openInventoryModal() {
      if (!this.form.warehouse) {
        this.$message.warn("请选择仓库");
        return;
      }
      if (!this.form.client) {
        this.$message.warn("请选择客户");
        return;
      }
      this.materialModalVisible = true;
    },
    stopLoading() {
      this.tableLoading = false;
    },
    removeMaterial(item, index) {
      this.tableLoading = true;
      this.deliveryMaterialItems.splice(index, 1);
      setTimeout(() => {
        this.stopLoading();
      }, 300);
    },
    copyMaterial(item, index) {
      this.tableLoading = true;
      this.deliveryMaterialItems.splice(index, 0, { ...item });
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
      console.log(selectedRowKeys);
    },
    batchAutoPicking() {
      if (!this.selectedRowKeys.length) {
        this.$message.warning("请选择要自动拣配的产品!");
        return false;
      }

      for (let index of this.selectedRowKeys) {
        const item = this.deliveryMaterialItems[index];
        console.log(item);
        if (item.total_quantity) {
          this.autoPicking(item, index);
        }
      }
    },
    autoPicking(item, index) {
      this.tableLoading = true;
      let searchForm = {
        page: 1,
        page_size: 999999,
        has_stock: true,
        warehouse: this.form.warehouse,
        material: item.material,
      };
      batchOption(searchForm)
        .then((data) => {
          let materialItems = [];
          let totalQuantity = item.total_quantity;
          for (let batchItem of data.results) {
            if (totalQuantity > batchItem.remain_quantity) {
              materialItems.push({
                ...item,
                batch: batchItem.id,
                batch_number: batchItem.number,
                total_quantity: batchItem.remain_quantity,
              });

              totalQuantity -= batchItem.remain_quantity;
            } else {
              materialItems.push({
                ...item,
                batch: batchItem.id,
                batch_number: batchItem.number,
                total_quantity: totalQuantity,
              });

              break;
            }
          }
          this.deliveryMaterialItems.splice(index, 1, ...materialItems);
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    changeExpress(value) {
      const onlineLogistics = ["顺丰快递", "德邦快递", "德邦物流", "京东快递", "京东物流", "亚德物流", "中通快递"];
      const offlineLogistics = ["市内配送", "顺丰同城", "货拉拉", "闪送", "自提"];
      if (onlineLogistics.some((logistics) => logistics === value)) {
        this.form.delivery_method = "线上配送";
      }

      if (offlineLogistics.some((logistics) => logistics === value)) {
        this.form.delivery_method = "线下配送";
      }
    },
    filterExpressOption(input, option) {
      return option.componentOptions.children[0].text.indexOf(input) >= 0;
    },
  },
  mounted() {
    this.initData();
  },
};
</script>
